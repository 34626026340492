import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { GridRow, GridCol } from 'Components/Layout';
import { Heading, Body, Jumbo, SmallBody } from 'Components/Text';
import usePolymorph from 'Hooks/usePolymorph';

import { useWindowSizes } from 'Hooks/useWindowMeasures';
import useCities from 'Hooks/useCities';

import {
  BackgroundContainer,
  BackgroundContainerLeft,
  BackgroundContainerRight,
  InfoContainer,
  InfoPrice,
  ReadMoreLink,
  NoMarginBody
} from './styles';

import { useTranslate } from '../../../features/polyglot';

const ServiceDetailTop = ({ service }) => {
  const { attributes } = service.data;
  const t = useTranslate('home.services');

  const { isTablet } = useWindowSizes();

  const description = attributes?.description;
  const [collapsed, setCollapsed] = useState(true);
  const { city } = useCities();
  const activeServicePrice = {
    client_price_standard: attributes.default_price,
    unit: attributes.default_unit
  };
  const defaultUnits = {
    day: `/ ${t('day')}`,
    hour: `/ ${t('hour')}`,
    person: `/ ${t('person')}`
  };

  const headerInfo = useMemo(() => {
    return attributes
      ? {
          title: attributes.name,
          subtitle: t('subtitle'),
          pricing: activeServicePrice?.client_price_standard,
          pricingHours: defaultUnits[activeServicePrice?.unit],
          vat: t('VAT'),
          body: description,
          parentCategory: attributes?.parent_category
        }
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeServicePrice?.client_price_standard,
    activeServicePrice?.unit,
    attributes,
    defaultUnits,
    description,
    t
  ]);

  const extractor = {
    'service-section': (props, element) => {
      props.title = getElementValueByName(element, 'service-title');
      props.content = getElementValueByName(element, 'service-description');
    }
  };
  const pageElements = useMemo(
    () => ({
      'service-section': ({ title, content }) => (
        <GridCol className={'service-section'}>
          <Jumbo size={6}>{title}</Jumbo>
          <NoMarginBody>{headerInfo.subtitle}</NoMarginBody>
          <InfoPrice>
            <Heading size={3}>{headerInfo.pricing}€</Heading>
            <SmallBody>{headerInfo.pricingHours}</SmallBody>
          </InfoPrice>
          <SmallBody className={'vatInfo'}>{headerInfo.vat}</SmallBody>
          <Body collapsed={collapsed}>{content}</Body>
          {isTablet && (
            <ReadMoreLink onClick={() => setCollapsed(!collapsed)}>
              {!collapsed ? t('seeLess') : t('seeMore')}
            </ReadMoreLink>
          )}
        </GridCol>
      ),
      title: () => <></>
    }),
    [
      collapsed,
      headerInfo.pricing,
      headerInfo.pricingHours,
      headerInfo.vat,
      headerInfo.subtitle,
      isTablet,
      t
    ]
  );
  const { parsedContent, getElementValueByName } = usePolymorph({
    id: attributes.content_key,
    pageElements,
    extractor,
    dependencies: [collapsed, city]
  });
  return (
    <BackgroundContainer>
      <BackgroundContainerLeft
        collapsed={collapsed}
        bgColor={attributes.parent_category}
      >
        <InfoContainer collapsed={collapsed}>
          {Object.keys(parsedContent).length && (
            <GridRow>{parsedContent['service-section']}</GridRow>
          )}
        </InfoContainer>
      </BackgroundContainerLeft>
      <BackgroundContainerRight img={attributes?.cover} />
    </BackgroundContainer>
  );
};

ServiceDetailTop.propTypes = {
  service: PropTypes.object.isRequired
};

export default ServiceDetailTop;
